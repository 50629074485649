import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'app-main-loader',
  standalone: true,
  imports: [],
  templateUrl: './main-loader.component.html',
  styleUrl: './main-loader.component.scss',
})
export class MainLoaderComponent {
  @Input() width: number = 100;
  @Input() height: number = 100;
}
